/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The TemplateCategoryJsonldOrderRead model module.
 * @module model/TemplateCategoryJsonldOrderRead
 * @version 1.0.0
 */
export class TemplateCategoryJsonldOrderRead {
  /**
   * Constructs a new <code>TemplateCategoryJsonldOrderRead</code>.
   * @alias module:model/TemplateCategoryJsonldOrderRead
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>TemplateCategoryJsonldOrderRead</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TemplateCategoryJsonldOrderRead} obj Optional instance to populate.
   * @return {module:model/TemplateCategoryJsonldOrderRead} The populated <code>TemplateCategoryJsonldOrderRead</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TemplateCategoryJsonldOrderRead();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], Object);
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], Object);
      if (data.hasOwnProperty('comment'))
        obj.comment = ApiClient.convertToType(data['comment'], Object);
      if (data.hasOwnProperty('colorHex'))
        obj.colorHex = ApiClient.convertToType(data['colorHex'], Object);
      if (data.hasOwnProperty('items'))
        obj.items = ApiClient.convertToType(data['items'], Object);
    }
    return obj;
  }
}

/**
 * @member {Object} id
 */
TemplateCategoryJsonldOrderRead.prototype.id = undefined;

/**
 * @member {Object} name
 */
TemplateCategoryJsonldOrderRead.prototype.name = undefined;

/**
 * @member {Object} comment
 */
TemplateCategoryJsonldOrderRead.prototype.comment = undefined;

/**
 * Get the value of colorHex
 * @member {Object} colorHex
 */
TemplateCategoryJsonldOrderRead.prototype.colorHex = undefined;

/**
 * @member {Object} items
 */
TemplateCategoryJsonldOrderRead.prototype.items = undefined;

