/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ItemJsonldOrderRead model module.
 * @module model/ItemJsonldOrderRead
 * @version 1.0.0
 */
export class ItemJsonldOrderRead {
  /**
   * Constructs a new <code>ItemJsonldOrderRead</code>.
   * @alias module:model/ItemJsonldOrderRead
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ItemJsonldOrderRead</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ItemJsonldOrderRead} obj Optional instance to populate.
   * @return {module:model/ItemJsonldOrderRead} The populated <code>ItemJsonldOrderRead</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ItemJsonldOrderRead();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], Object);
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], Object);
      if (data.hasOwnProperty('kind'))
        obj.kind = ApiClient.convertToType(data['kind'], Object);
      if (data.hasOwnProperty('quantity'))
        obj.quantity = ApiClient.convertToType(data['quantity'], Object);
    }
    return obj;
  }
}

/**
 * Get item ID.
 * @member {Object} id
 */
ItemJsonldOrderRead.prototype.id = undefined;

/**
 * Get item name.
 * @member {Object} name
 */
ItemJsonldOrderRead.prototype.name = undefined;

/**
 * Get item kind.
 * @member {Object} kind
 */
ItemJsonldOrderRead.prototype.kind = undefined;

/**
 * Get item quantity.
 * @member {Object} quantity
 */
ItemJsonldOrderRead.prototype.quantity = undefined;

