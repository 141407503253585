/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AccommodationJsonldRoomWrite} from './AccommodationJsonldRoomWrite';

/**
 * The RoomJsonldRoomWrite model module.
 * @module model/RoomJsonldRoomWrite
 * @version 1.0.0
 */
export class RoomJsonldRoomWrite {
  /**
   * Constructs a new <code>RoomJsonldRoomWrite</code>.
   * @alias module:model/RoomJsonldRoomWrite
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RoomJsonldRoomWrite</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RoomJsonldRoomWrite} obj Optional instance to populate.
   * @return {module:model/RoomJsonldRoomWrite} The populated <code>RoomJsonldRoomWrite</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RoomJsonldRoomWrite();
      if (data.hasOwnProperty('roomNumber'))
        obj.roomNumber = ApiClient.convertToType(data['roomNumber'], Object);
      if (data.hasOwnProperty('roomType'))
        obj.roomType = ApiClient.convertToType(data['roomType'], Object);
      if (data.hasOwnProperty('accommodation'))
        obj.accommodation = AccommodationJsonldRoomWrite.constructFromObject(data['accommodation']);
    }
    return obj;
  }
}

/**
 * Get room number.
 * @member {Object} roomNumber
 */
RoomJsonldRoomWrite.prototype.roomNumber = undefined;

/**
 * Get room type.
 * @member {Object} roomType
 */
RoomJsonldRoomWrite.prototype.roomType = undefined;

/**
 * Get accommodation.
 * @member {module:model/AccommodationJsonldRoomWrite} accommodation
 */
RoomJsonldRoomWrite.prototype.accommodation = undefined;

