/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The OrderJsonldOrderWrite model module.
 * @module model/OrderJsonldOrderWrite
 * @version 1.0.0
 */
export class OrderJsonldOrderWrite {
  /**
   * Constructs a new <code>OrderJsonldOrderWrite</code>.
   * @alias module:model/OrderJsonldOrderWrite
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>OrderJsonldOrderWrite</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OrderJsonldOrderWrite} obj Optional instance to populate.
   * @return {module:model/OrderJsonldOrderWrite} The populated <code>OrderJsonldOrderWrite</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrderJsonldOrderWrite();
      if (data.hasOwnProperty('accommodation'))
        obj.accommodation = ApiClient.convertToType(data['accommodation'], Object);
      if (data.hasOwnProperty('firstName'))
        obj.firstName = ApiClient.convertToType(data['firstName'], Object);
      if (data.hasOwnProperty('lastName'))
        obj.lastName = ApiClient.convertToType(data['lastName'], Object);
      if (data.hasOwnProperty('dateOfArrival'))
        obj.dateOfArrival = ApiClient.convertToType(data['dateOfArrival'], Object);
      if (data.hasOwnProperty('dateOfDeparture'))
        obj.dateOfDeparture = ApiClient.convertToType(data['dateOfDeparture'], Object);
      if (data.hasOwnProperty('categories'))
        obj.categories = ApiClient.convertToType(data['categories'], Object);
      if (data.hasOwnProperty('specialRequests'))
        obj.specialRequests = ApiClient.convertToType(data['specialRequests'], Object);
    }
    return obj;
  }
}

/**
 * Get the value of accommodation
 * @member {Object} accommodation
 */
OrderJsonldOrderWrite.prototype.accommodation = undefined;

/**
 * Get the value of firstName
 * @member {Object} firstName
 */
OrderJsonldOrderWrite.prototype.firstName = undefined;

/**
 * Get the value of lastName
 * @member {Object} lastName
 */
OrderJsonldOrderWrite.prototype.lastName = undefined;

/**
 * Get the value of dateOfArrival
 * @member {Object} dateOfArrival
 */
OrderJsonldOrderWrite.prototype.dateOfArrival = undefined;

/**
 * Get the value of dateOfDeparture
 * @member {Object} dateOfDeparture
 */
OrderJsonldOrderWrite.prototype.dateOfDeparture = undefined;

/**
 * @member {Object} categories
 */
OrderJsonldOrderWrite.prototype.categories = undefined;

/**
 * Get the value of specialRequests
 * @member {Object} specialRequests
 */
OrderJsonldOrderWrite.prototype.specialRequests = undefined;

