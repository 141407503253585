/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The InlineResponse200Hydrasearch model module.
 * @module model/InlineResponse200Hydrasearch
 * @version 1.0.0
 */
export class InlineResponse200Hydrasearch {
  /**
   * Constructs a new <code>InlineResponse200Hydrasearch</code>.
   * @alias module:model/InlineResponse200Hydrasearch
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>InlineResponse200Hydrasearch</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse200Hydrasearch} obj Optional instance to populate.
   * @return {module:model/InlineResponse200Hydrasearch} The populated <code>InlineResponse200Hydrasearch</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse200Hydrasearch();
      if (data.hasOwnProperty('@type'))
        obj.type = ApiClient.convertToType(data['@type'], Object);
      if (data.hasOwnProperty('hydra:template'))
        obj.hydratemplate = ApiClient.convertToType(data['hydra:template'], Object);
      if (data.hasOwnProperty('hydra:variableRepresentation'))
        obj.hydravariableRepresentation = ApiClient.convertToType(data['hydra:variableRepresentation'], Object);
      if (data.hasOwnProperty('hydra:mapping'))
        obj.hydramapping = ApiClient.convertToType(data['hydra:mapping'], Object);
    }
    return obj;
  }
}

/**
 * @member {Object} type
 */
InlineResponse200Hydrasearch.prototype.type = undefined;

/**
 * @member {Object} hydratemplate
 */
InlineResponse200Hydrasearch.prototype.hydratemplate = undefined;

/**
 * @member {Object} hydravariableRepresentation
 */
InlineResponse200Hydrasearch.prototype.hydravariableRepresentation = undefined;

/**
 * @member {Object} hydramapping
 */
InlineResponse200Hydrasearch.prototype.hydramapping = undefined;

