/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ApiGetTokenBody model module.
 * @module model/ApiGetTokenBody
 * @version 1.0.0
 */
export class ApiGetTokenBody {
  /**
   * Constructs a new <code>ApiGetTokenBody</code>.
   * @alias module:model/ApiGetTokenBody
   * @class
   * @param username {Object} 
   * @param password {Object} 
   */
  constructor(username, password) {
    this.username = username;
    this.password = password;
  }

  /**
   * Constructs a <code>ApiGetTokenBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ApiGetTokenBody} obj Optional instance to populate.
   * @return {module:model/ApiGetTokenBody} The populated <code>ApiGetTokenBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ApiGetTokenBody();
      if (data.hasOwnProperty('username'))
        obj.username = ApiClient.convertToType(data['username'], Object);
      if (data.hasOwnProperty('password'))
        obj.password = ApiClient.convertToType(data['password'], Object);
    }
    return obj;
  }
}

/**
 * @member {Object} username
 */
ApiGetTokenBody.prototype.username = undefined;

/**
 * @member {Object} password
 */
ApiGetTokenBody.prototype.password = undefined;

