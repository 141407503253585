/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {InlineResponse2009} from '../model/InlineResponse2009';
import {TemplateCategoryJsonldCategoryRead} from '../model/TemplateCategoryJsonldCategoryRead';
import {TemplateCategoryJsonldCategoryWrite} from '../model/TemplateCategoryJsonldCategoryWrite';

/**
* TemplateCategory service.
* @module api/TemplateCategoryApi
* @version 1.0.0
*/
export class TemplateCategoryApi {

    /**
    * Constructs a new TemplateCategoryApi. 
    * @alias module:api/TemplateCategoryApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiTemplateCategoriesGetCollection operation.
     * @callback moduleapi/TemplateCategoryApi~apiTemplateCategoriesGetCollectionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2009{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves the collection of TemplateCategory resources.
     * Retrieves the collection of TemplateCategory resources.
     * @param {Object} opts Optional parameters
     * @param {Object} opts.page The collection page number (default to <.>)
     * @param {module:api/TemplateCategoryApi~apiTemplateCategoriesGetCollectionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiTemplateCategoriesGetCollection(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'page': opts['page']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/ld+json'];
      let returnType = InlineResponse2009;

      return this.apiClient.callApi(
        '/api/template_categories', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiTemplateCategoriesIdDelete operation.
     * @callback moduleapi/TemplateCategoryApi~apiTemplateCategoriesIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Removes the TemplateCategory resource.
     * Removes the TemplateCategory resource.
     * @param {Object} id TemplateCategory identifier
     * @param {module:api/TemplateCategoryApi~apiTemplateCategoriesIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiTemplateCategoriesIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiTemplateCategoriesIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/template_categories/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiTemplateCategoriesIdGet operation.
     * @callback moduleapi/TemplateCategoryApi~apiTemplateCategoriesIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TemplateCategoryJsonldCategoryRead{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves a TemplateCategory resource.
     * Retrieves a TemplateCategory resource.
     * @param {Object} id TemplateCategory identifier
     * @param {module:api/TemplateCategoryApi~apiTemplateCategoriesIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiTemplateCategoriesIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiTemplateCategoriesIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/ld+json'];
      let returnType = TemplateCategoryJsonldCategoryRead;

      return this.apiClient.callApi(
        '/api/template_categories/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiTemplateCategoriesIdPut operation.
     * @callback moduleapi/TemplateCategoryApi~apiTemplateCategoriesIdPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TemplateCategoryJsonldCategoryRead{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Replaces the TemplateCategory resource.
     * Replaces the TemplateCategory resource.
     * @param {module:model/TemplateCategoryJsonldCategoryWrite} body The updated TemplateCategory resource
     * @param {Object} id TemplateCategory identifier
     * @param {module:api/TemplateCategoryApi~apiTemplateCategoriesIdPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiTemplateCategoriesIdPut(body, id, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling apiTemplateCategoriesIdPut");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiTemplateCategoriesIdPut");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/ld+json'];
      let accepts = ['application/ld+json'];
      let returnType = TemplateCategoryJsonldCategoryRead;

      return this.apiClient.callApi(
        '/api/template_categories/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiTemplateCategoriesPost operation.
     * @callback moduleapi/TemplateCategoryApi~apiTemplateCategoriesPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TemplateCategoryJsonldCategoryRead{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates a TemplateCategory resource.
     * Creates a TemplateCategory resource.
     * @param {module:model/TemplateCategoryJsonldCategoryWrite} body The new TemplateCategory resource
     * @param {module:api/TemplateCategoryApi~apiTemplateCategoriesPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiTemplateCategoriesPost(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling apiTemplateCategoriesPost");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/ld+json'];
      let accepts = ['application/ld+json'];
      let returnType = TemplateCategoryJsonldCategoryRead;

      return this.apiClient.callApi(
        '/api/template_categories', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}