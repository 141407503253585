/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The InlineResponse200Hydraview model module.
 * @module model/InlineResponse200Hydraview
 * @version 1.0.0
 */
export class InlineResponse200Hydraview {
  /**
   * Constructs a new <code>InlineResponse200Hydraview</code>.
   * @alias module:model/InlineResponse200Hydraview
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>InlineResponse200Hydraview</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse200Hydraview} obj Optional instance to populate.
   * @return {module:model/InlineResponse200Hydraview} The populated <code>InlineResponse200Hydraview</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse200Hydraview();
      if (data.hasOwnProperty('@id'))
        obj.id = ApiClient.convertToType(data['@id'], Object);
      if (data.hasOwnProperty('@type'))
        obj.type = ApiClient.convertToType(data['@type'], Object);
      if (data.hasOwnProperty('hydra:first'))
        obj.hydrafirst = ApiClient.convertToType(data['hydra:first'], Object);
      if (data.hasOwnProperty('hydra:last'))
        obj.hydralast = ApiClient.convertToType(data['hydra:last'], Object);
      if (data.hasOwnProperty('hydra:previous'))
        obj.hydraprevious = ApiClient.convertToType(data['hydra:previous'], Object);
      if (data.hasOwnProperty('hydra:next'))
        obj.hydranext = ApiClient.convertToType(data['hydra:next'], Object);
    }
    return obj;
  }
}

/**
 * @member {Object} id
 */
InlineResponse200Hydraview.prototype.id = undefined;

/**
 * @member {Object} type
 */
InlineResponse200Hydraview.prototype.type = undefined;

/**
 * @member {Object} hydrafirst
 */
InlineResponse200Hydraview.prototype.hydrafirst = undefined;

/**
 * @member {Object} hydralast
 */
InlineResponse200Hydraview.prototype.hydralast = undefined;

/**
 * @member {Object} hydraprevious
 */
InlineResponse200Hydraview.prototype.hydraprevious = undefined;

/**
 * @member {Object} hydranext
 */
InlineResponse200Hydraview.prototype.hydranext = undefined;

