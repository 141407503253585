import { forwardRef, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MDInputRoot from "components/MDInput/MDInputRoot";
import { LoadScript, useJsApiLoader } from "@react-google-maps/api";

function extractAddress(data) {
  let description = data.adr_address.replace(/<\/?[^>]+(>|$)/g, "");
  let place_id = data.place_id;
  let name = data.name || ""; // include the name of the place
  let street = "";
  let city = "";
  let zipCode = "";
  let country = "";

  data.address_components.forEach((component) => {
    if (component.types.includes("street_number")) {
      street = `${component.long_name} `;
    }
    if (component.types.includes("route")) {
      street += component.long_name;
    }
    if (component.types.includes("locality")) {
      city = component.long_name;
    }
    if (component.types.includes("postal_code")) {
      zipCode = component.long_name;
    }
    if (component.types.includes("country")) {
      country = component.long_name;
    }
  });

  // handle special locations
  if (data.types.includes("airport")) {
    description = `${name}, ${city}, ${country}`;
  } else if (name) {
    description = `${name}, ${description}`;
  }

  return { description, place_id, name, street, city, zipCode, country };
}

const generateDescription = (location) => {
  let description = "";

  if (location.street) {
    description += `${location.street}, `;
  }
  if (location.city) {
    description += `${location.city}, `;
  }
  if (location.zipCode) {
    description += `${location.zipCode}, `;
  }
  if (location.country) {
    description += location.country;
  }

  if (description.endsWith(", ")) {
    description = description.slice(0, -2);
  }

  if (location.name) {
    description = `${location.name}, ${description}`;
  }

  return description;
};

const LocationAutocomplete = forwardRef(
  (
    { error, success, disabled, onAddressChange, address, label, additionalOptions, ...rest },
    ref
  ) => {
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedValue, setSelectedValue] = useState(null);
    const [apiEnabled, setApiEnabled] = useState(true);
    const [localAdditionalOptions, setLocalAdditionalOptions] = useState([]);
    const serviceRef = useRef();

    const setAddress = (address) => {
      if (address) {
        setApiEnabled(false);
        let place_id = address.place_id;
        if (!address.place_id) {
          place_id = address.street;
        }

        const option = {
          description: address.description,
          place_id: place_id, // or any unique identifier
        };

        setSelectedValue(option);
        setSuggestions([option]);

        onAddressChange(address);
      } else {
        setSelectedValue(null);
      }
    };

    useEffect(() => {
      setAddress(address);
    }, [address]);

    useEffect(() => {
      const enrichedOptions = additionalOptions.map((option) => ({
        ...option,
        description: generateDescription(option),
        place_id: option.place_id || "fl-orders-id-" + Math.random().toString(36).substr(2, 30),
      }));
      //sort alphabetically by description
      enrichedOptions.sort((a, b) => (a.description > b.description ? 1 : -1));

      setLocalAdditionalOptions(enrichedOptions);
      setSuggestions(enrichedOptions);
    }, [additionalOptions]);

    useEffect(() => {
      serviceRef.current = new window.google.maps.places.AutocompleteService();
    }, []);

    useEffect(() => {
      if (!apiEnabled) {
        return;
      }

      if (inputValue.length < 1) {
        setSelectedValue(null);
        return;
      }

      const filteredOptions = localAdditionalOptions.filter((option) =>
        option.description.toLowerCase().includes(inputValue.toLowerCase())
      );

      if (filteredOptions.length >= 2) {
        setSuggestions(filteredOptions);
        return;
      }

      serviceRef.current.getPlacePredictions({ input: inputValue }, (predictions) => {
        if (Array.isArray(predictions)) {
          if (
            selectedValue &&
            !predictions.find((p) => p.place_id === selectedValue.place_id) &&
            !filteredOptions.find((p) => p.place_id === selectedValue.place_id)
          ) {
            predictions.push(selectedValue);
          }
          setSuggestions([...filteredOptions, ...predictions]);
        } else {
          console.error("Failed to get place predictions:", predictions);
        }
      });
    }, [inputValue, additionalOptions]);

    const handleSelection = (event, value) => {
      if (value) {
        if (value.place_id.startsWith("fl-orders-id-")) {
          onAddressChange(value);
        } else {
          const service = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );
          service.getDetails({ placeId: value.place_id }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              onAddressChange(extractAddress(place)); // Here you have the full details of the selected place
            }
          });
        }
      } else {
        onAddressChange(null);
      }
    };

    return (
      <Autocomplete
        options={suggestions}
        value={selectedValue}
        getOptionLabel={(option) => option.description || option.name}
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          if (event) {
            // Check if the event object is not null
            setApiEnabled(true); // Enable the API when the user types something
          }
        }}
        onChange={(event, newValue) => {
          setSelectedValue(newValue);
          handleSelection(event, newValue);
        }}
        renderInput={(params) => (
          <TextField
            error={error}
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              inputRef: ref,
            }}
          />
        )}
        filterOptions={(options) => options.description || options} // Add this line
        renderOption={(props, option) => {
          const parts = option.matched_substrings || [];
          let lastIndex = 0;
          const children = [];

          const description = option.description || option.name;

          parts.forEach((part, index) => {
            if (part.offset > lastIndex) {
              children.push(
                <span key={`normal-${index}`} style={{ whiteSpace: "pre" }}>
                  {description.substring(lastIndex, part.offset)}
                </span>
              );
            }

            children.push(
              <span key={`highlight-${index}`} style={{ whiteSpace: "pre", fontWeight: 700 }}>
                {description.substr(part.offset, part.length)}
              </span>
            );

            lastIndex = part.offset + part.length;
          });

          if (description.length > lastIndex) {
            children.push(
              <span key="end" style={{ whiteSpace: "pre" }}>
                {description.substring(lastIndex)}
              </span>
            );
          }

          return (
            <li {...props} key={option.place_id}>
              {children}
            </li>
          );
        }}
      />
    );
  }
);

LocationAutocomplete.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

LocationAutocomplete.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  onAddressChange: PropTypes.func.isRequired,
  address: PropTypes.object,
  label: PropTypes.string,
  additionalOptions: PropTypes.array,
};

export default LocationAutocomplete;
