/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The CompanyJsonldShuttleOrderRead model module.
 * @module model/CompanyJsonldShuttleOrderRead
 * @version 1.0.0
 */
export class CompanyJsonldShuttleOrderRead {
  /**
   * Constructs a new <code>CompanyJsonldShuttleOrderRead</code>.
   * @alias module:model/CompanyJsonldShuttleOrderRead
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CompanyJsonldShuttleOrderRead</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CompanyJsonldShuttleOrderRead} obj Optional instance to populate.
   * @return {module:model/CompanyJsonldShuttleOrderRead} The populated <code>CompanyJsonldShuttleOrderRead</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CompanyJsonldShuttleOrderRead();
      if (data.hasOwnProperty('code'))
        obj.code = ApiClient.convertToType(data['code'], Object);
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], Object);
      if (data.hasOwnProperty('dispoEmail'))
        obj.dispoEmail = ApiClient.convertToType(data['dispoEmail'], Object);
    }
    return obj;
  }
}

/**
 * Get the value of code
 * @member {Object} code
 */
CompanyJsonldShuttleOrderRead.prototype.code = undefined;

/**
 * Get the value of name
 * @member {Object} name
 */
CompanyJsonldShuttleOrderRead.prototype.name = undefined;

/**
 * Get the value of dispoEmail
 * @member {Object} dispoEmail
 */
CompanyJsonldShuttleOrderRead.prototype.dispoEmail = undefined;

