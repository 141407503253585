/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The CategoryJsonldOrderWrite model module.
 * @module model/CategoryJsonldOrderWrite
 * @version 1.0.0
 */
export class CategoryJsonldOrderWrite {
  /**
   * Constructs a new <code>CategoryJsonldOrderWrite</code>.
   * @alias module:model/CategoryJsonldOrderWrite
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CategoryJsonldOrderWrite</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CategoryJsonldOrderWrite} obj Optional instance to populate.
   * @return {module:model/CategoryJsonldOrderWrite} The populated <code>CategoryJsonldOrderWrite</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CategoryJsonldOrderWrite();
      if (data.hasOwnProperty('madeFrom'))
        obj.madeFrom = ApiClient.convertToType(data['madeFrom'], Object);
      if (data.hasOwnProperty('order'))
        obj.order = ApiClient.convertToType(data['order'], Object);
      if (data.hasOwnProperty('colorHex'))
        obj.colorHex = ApiClient.convertToType(data['colorHex'], Object);
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], Object);
      if (data.hasOwnProperty('comment'))
        obj.comment = ApiClient.convertToType(data['comment'], Object);
      if (data.hasOwnProperty('items'))
        obj.items = ApiClient.convertToType(data['items'], Object);
    }
    return obj;
  }
}

/**
 * Get the value of madeFrom
 * @member {Object} madeFrom
 */
CategoryJsonldOrderWrite.prototype.madeFrom = undefined;

/**
 * @member {Object} order
 */
CategoryJsonldOrderWrite.prototype.order = undefined;

/**
 * Set the value of colorHex
 * @member {Object} colorHex
 */
CategoryJsonldOrderWrite.prototype.colorHex = undefined;

/**
 * @member {Object} name
 */
CategoryJsonldOrderWrite.prototype.name = undefined;

/**
 * @member {Object} comment
 */
CategoryJsonldOrderWrite.prototype.comment = undefined;

/**
 * @member {Object} items
 */
CategoryJsonldOrderWrite.prototype.items = undefined;

