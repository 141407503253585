// React imports
import React, { useEffect, useRef, useState } from "react";

// Material-UI imports
import { Card, CardContent, CardMedia, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import GetAppIcon from "@mui/icons-material/GetApp";

// API imports
import { AccommodationApi } from "api/src/api/AccommodationApi"; // adjust the import according to your project structure
import { TemplateCategoryApi } from "api/src";
import { OrderApi } from "api/src";

// Component imports
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ConciergeLayout from "./components/ConciergeLayout";
import Category from "./components/Category";

// Utility imports
import { lightenColor } from "utils/utils";

// Asset imports
import bgImage from "assets/images/concierge/davos.jpg";
import mapImage from "assets/images/concierge/square.png";
import headerImage from "assets/images/concierge/header.jpg";

const Concierge = () => {
  const [accommodations, setAccommodations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [order, setOrder] = useState({
    accommodation: {},
    dateOfArrival: "",
    dateOfDeparture: "",
    firstName: "",
    lastName: "",
    categories: [],
    specialRequests: "",
  });
  const [accommodationError, setAccommodationError] = useState(false);
  const [orderResponse, setOrderResponse] = useState(null);

  const accommodationApi = new AccommodationApi();
  const templateCategoryApi = new TemplateCategoryApi();
  const theme = useTheme();

  // Create a ref for the top element
  const topElementRef = useRef(null);

  const specialRequestColor = "#002985";
  const lighterColor = lightenColor(specialRequestColor, 20);
  const specialRequestGradient = `linear-gradient(45deg, ${specialRequestColor} 20%, ${lighterColor} 80%)`;

  const handleSubmit = () => {
    // Add your checks here
    if (!order.accommodation || !order.accommodation["@id"]) {
      setAccommodationError(true);
      topElementRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }
    console.log(order);
    const orderApi = new OrderApi();
    orderApi.apiOrdersPost(order, (error, data, response) => {
      if (error) {
        console.error(error);
      } else {
        setOrderResponse(response.body);
      }
    });
  };

  const handleCategoryChange = (changedCategory) => {
    console.log(changedCategory);
    setOrder((prevOrder) => ({
      ...prevOrder,
      categories: [
        ...prevOrder.categories.filter((c) => c.madeFrom !== changedCategory.madeFrom),
        changedCategory,
      ],
    }));
  };

  const handleDownload = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;

    // Extract filename from the URL
    const urlObj = new URL(url);
    const filename = urlObj.pathname.split("/").pop();

    link.download = filename;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Fetch the accommodations
  useEffect(() => {
    accommodationApi.apiAccommodationsGetCollection({}, (error, data, response) => {
      if (error) {
        console.error("An error occurred while fetching the accommodations:", error);
      } else {
        console.log("API called successfully. Returned data:", data);
        setAccommodations(data.hydramember);
      }
    });
  }, []);

  // Fetch the template categories
  useEffect(() => {
    templateCategoryApi.apiTemplateCategoriesGetCollection({}, (error, data, response) => {
      if (error) {
        console.error("An error occurred while fetching the template categories:", error);
      } else {
        console.log("API called successfully. Returned data:", data);
        const categories = data.hydramember.map((templateCategory) => {
          const { id, ...rest } = templateCategory;
          return {
            madeFrom: templateCategory["@id"],
            colorHex: rest.colorHex,
            name: rest.name,
            comment: rest.comment || "",
            items: rest.items.map(({ "@id": _, "@type": __, id: ___, ...itemRest }) => itemRest),
          };
        });
        setCategories(categories);
        // Set the order categories
        setOrder((prevOrder) => ({
          ...prevOrder,
          categories: categories,
        }));
        console.log("order", order);
      }
    });
  }, []);

  return (
    <ConciergeLayout image={bgImage}>
      <form
        onSubmit={(e) => {
          e.preventDefault(); // Prevent the default form submission
          handleSubmit(); // Call your submit function
        }}
      >
        {/*--------------------  Special Requests -----------------------  */}
        <MDBox py={2}>
          <Card sx={{ borderRadius: 1, boxShadow: 5 }} variant="outlined">
            <CardContent p={4}>
              <div ref={topElementRef}>
                <CardMedia
                  component="img"
                  sx={{ width: "100%", marginLeft: 0, marginRight: 0, marginBottom: 4 }}
                  image={headerImage}
                  title="header"
                />
              </div>
              {orderResponse ? (
                <Grid container justifyContent="center" alignItems="center" direction="column">
                  <MDTypography variant="h4" gutterBottom>
                    Order Confirmation
                  </MDTypography>
                  <MDTypography variant="body1" gutterBottom>
                    Thank you for your Order! You can download the order confirmation below.
                  </MDTypography>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    style={{ maxWidth: "500px", marginBottom: "20px" }}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item xs={6}>
                        <MDTypography variant="body1" fontWeight="bold">
                          Full Name:
                        </MDTypography>
                      </Grid>
                      <Grid item xs={6}>
                        <MDTypography variant="body1">{`${orderResponse.firstName} ${orderResponse.lastName}`}</MDTypography>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={6}>
                        <MDTypography variant="body1" fontWeight="bold">
                          Order Number:
                        </MDTypography>
                      </Grid>
                      <Grid item xs={6}>
                        <MDTypography variant="body1">{orderResponse.orderNumber}</MDTypography>
                      </Grid>
                    </Grid>
                    {/* ... */}
                    <Grid container justifyContent="space-between">
                      <Grid item xs={6}>
                        <MDTypography variant="body1" fontWeight="bold">
                          Accommodation:
                        </MDTypography>
                      </Grid>
                      <Grid item xs={6}>
                        <MDTypography variant="body1">
                          {orderResponse.accommodation.name}, {orderResponse.accommodation.address}
                        </MDTypography>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={6}>
                        <MDTypography variant="body1" fontWeight="bold">
                          Travel Dates:
                        </MDTypography>
                      </Grid>
                      <Grid item xs={6}>
                        <MDTypography variant="body1">
                          {`${new Date(
                            orderResponse.dateOfArrival
                          ).toLocaleDateString()} - ${new Date(
                            orderResponse.dateOfDeparture
                          ).toLocaleDateString()}`}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <MDButton
                    variant="contained"
                    color="success"
                    startIcon={<GetAppIcon />}
                    onClick={() =>
                      handleDownload(
                        `${orderResponse.orderConfirmationPdfUrl}?code=${orderResponse.code}`
                      )
                    }
                  >
                    Download Order Confirmation
                  </MDButton>
                </Grid>
              ) : (
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <img
                      width="100%"
                      style={{ borderRadius: "0.75rem" }}
                      src={mapImage}
                      alt="Map"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={accommodations}
                          sx={{ width: "100%" }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          getOptionLabel={(option) => `(${option.NumberOnMap}) ${option.name}`}
                          renderInput={(params) => (
                            <TextField
                              error={accommodationError}
                              {...params}
                              label="Accommodation *"
                            />
                          )}
                          renderOption={(props, option, { selected }) => (
                            console.log(props),
                            (
                              <Box
                                component="li"
                                sx={{
                                  "& > :not(style)": { pl: 2, pr: 2 },
                                  ...(selected && { bgcolor: theme.palette.action.selected }),
                                }}
                                {...props}
                              >
                                <span style={{ fontWeight: "bold", width: "50%" }}>
                                  ({option.NumberOnMap}) {option.name}
                                </span>
                                <span style={{ fontStyle: "italic", width: "50%" }}>
                                  {" "}
                                  ({option.address})
                                </span>
                              </Box>
                            )
                          )}
                          onChange={(event, newValue) => {
                            setOrder((prevOrder) => ({
                              ...prevOrder,
                              accommodation: newValue ? { "@id": newValue["@id"] } : null,
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="First Name"
                              onChange={(e) => setOrder({ ...order, firstName: e.target.value })}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Last Name"
                              onChange={(e) => setOrder({ ...order, lastName: e.target.value })}
                              fullWidth
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Date of Arrival"
                              type="date"
                              fullWidth
                              onChange={(e) =>
                                setOrder({ ...order, dateOfArrival: e.target.value })
                              }
                              InputLabelProps={{ shrink: true }}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Date of Departure"
                              type="date"
                              fullWidth
                              onChange={(e) =>
                                setOrder({ ...order, dateOfDeparture: e.target.value })
                              }
                              InputLabelProps={{ shrink: true }}
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <MDTypography variant="body2" gutterBottom align="justify">
                          Fields marked with * are required.
                        </MDTypography>
                        <Divider />
                        <MDTypography variant="body2" gutterBottom align="justify">
                          In line with our sustainability initiatives, please detail your apartment
                          amenities in the form below before arrival. This approach helps us reduce
                          waste and unnecessary restocking.
                        </MDTypography>
                        <MDTypography variant="body2" gutterBottom align="justify">
                          For 2024, we&apos;ll provide coffee and tea, celebrating Davos&apos;s
                          high-quality tap water and reducing bottled water use. However, bottled
                          water is available upon request.
                        </MDTypography>
                        <Divider />
                        <MDTypography variant="body2" gutterBottom align="justify">
                          <b>For assistance, contact our Concierge Service:</b>
                          <br /> <b>Tamilla</b> at
                          <a href="tel:+41765160051"> +41 76 516 00 51</a>
                          <br /> <em>(Please, any request in written and via WhatsApp only)</em>
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </MDBox>
        {/*--------------------  Special Requests -----------------------  */}
        {!orderResponse ? (
          <>
            {categories.map((category, index) => (
              <Category
                key={index}
                category={category}
                onItemsChange={(newItems) => {
                  handleCategoryChange({ ...category, items: newItems });
                }}
              />
            ))}
            <MDBox py={2}>
              <Card sx={{ borderRadius: 1, boxShadow: 5 }} variant="outlined">
                <CardContent p={4}>
                  <MDBox
                    my={2}
                    p={2}
                    sx={{
                      background: specialRequestGradient,
                      borderRadius: "0.75rem",
                    }}
                  >
                    <h2 style={{ margin: 0, lineHeight: 1, color: "white" }}>Special Requests:</h2>
                  </MDBox>
                  <TextField
                    multiline
                    rows={7}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setOrder({ ...order, specialRequests: e.target.value })}
                  />
                  <MDButton
                    variant="gradient"
                    color="info"
                    style={{ marginTop: "16px", background: "#002985" }}
                    type="submit"
                  >
                    Place Order
                  </MDButton>
                </CardContent>
              </Card>
            </MDBox>
          </>
        ) : (
          <></>
        )}
      </form>
    </ConciergeLayout>
  );
};
export default Concierge;
