/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The AddressJsonldShuttleOrderWrite model module.
 * @module model/AddressJsonldShuttleOrderWrite
 * @version 1.0.0
 */
export class AddressJsonldShuttleOrderWrite {
  /**
   * Constructs a new <code>AddressJsonldShuttleOrderWrite</code>.
   * @alias module:model/AddressJsonldShuttleOrderWrite
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AddressJsonldShuttleOrderWrite</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AddressJsonldShuttleOrderWrite} obj Optional instance to populate.
   * @return {module:model/AddressJsonldShuttleOrderWrite} The populated <code>AddressJsonldShuttleOrderWrite</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AddressJsonldShuttleOrderWrite();
      if (data.hasOwnProperty('street'))
        obj.street = ApiClient.convertToType(data['street'], Object);
      if (data.hasOwnProperty('city'))
        obj.city = ApiClient.convertToType(data['city'], Object);
      if (data.hasOwnProperty('zipCode'))
        obj.zipCode = ApiClient.convertToType(data['zipCode'], Object);
      if (data.hasOwnProperty('country'))
        obj.country = ApiClient.convertToType(data['country'], Object);
    }
    return obj;
  }
}

/**
 * Get the value of street
 * @member {Object} street
 */
AddressJsonldShuttleOrderWrite.prototype.street = undefined;

/**
 * Get the value of city
 * @member {Object} city
 */
AddressJsonldShuttleOrderWrite.prototype.city = undefined;

/**
 * Get the value of zipCode
 * @member {Object} zipCode
 */
AddressJsonldShuttleOrderWrite.prototype.zipCode = undefined;

/**
 * Get the value of country
 * @member {Object} country
 */
AddressJsonldShuttleOrderWrite.prototype.country = undefined;

