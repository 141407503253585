/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The AccommodationJsonldAccommodationRead model module.
 * @module model/AccommodationJsonldAccommodationRead
 * @version 1.0.0
 */
export class AccommodationJsonldAccommodationRead {
  /**
   * Constructs a new <code>AccommodationJsonldAccommodationRead</code>.
   * @alias module:model/AccommodationJsonldAccommodationRead
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AccommodationJsonldAccommodationRead</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AccommodationJsonldAccommodationRead} obj Optional instance to populate.
   * @return {module:model/AccommodationJsonldAccommodationRead} The populated <code>AccommodationJsonldAccommodationRead</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AccommodationJsonldAccommodationRead();
      if (data.hasOwnProperty('@context'))
        obj.context = ApiClient.convertToType(data['@context'], Object);
      if (data.hasOwnProperty('@id'))
        obj.id = ApiClient.convertToType(data['@id'], Object);
      if (data.hasOwnProperty('@type'))
        obj.type = ApiClient.convertToType(data['@type'], Object);
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], Object);
      if (data.hasOwnProperty('referenceNumber'))
        obj.referenceNumber = ApiClient.convertToType(data['referenceNumber'], Object);
      if (data.hasOwnProperty('numberOnMap'))
        obj.numberOnMap = ApiClient.convertToType(data['numberOnMap'], Object);
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], Object);
      if (data.hasOwnProperty('address'))
        obj.address = ApiClient.convertToType(data['address'], Object);
      if (data.hasOwnProperty('rooms'))
        obj.rooms = ApiClient.convertToType(data['rooms'], Object);
      if (data.hasOwnProperty('orders'))
        obj.orders = ApiClient.convertToType(data['orders'], Object);
    }
    return obj;
  }
}

/**
 * @member {Object} context
 */
AccommodationJsonldAccommodationRead.prototype.context = undefined;

/**
 * @member {Object} id
 */
AccommodationJsonldAccommodationRead.prototype.id = undefined;

/**
 * @member {Object} type
 */
AccommodationJsonldAccommodationRead.prototype.type = undefined;

/**
 * Get the value of id
 * @member {Object} id
 */
AccommodationJsonldAccommodationRead.prototype.id = undefined;

/**
 * Get the value of referenceNumber
 * @member {Object} referenceNumber
 */
AccommodationJsonldAccommodationRead.prototype.referenceNumber = undefined;

/**
 * Get the value of NumberOnMap
 * @member {Object} numberOnMap
 */
AccommodationJsonldAccommodationRead.prototype.numberOnMap = undefined;

/**
 * Get the value of name
 * @member {Object} name
 */
AccommodationJsonldAccommodationRead.prototype.name = undefined;

/**
 * Get the value of address
 * @member {Object} address
 */
AccommodationJsonldAccommodationRead.prototype.address = undefined;

/**
 * Get the value of rooms
 * @member {Object} rooms
 */
AccommodationJsonldAccommodationRead.prototype.rooms = undefined;

/**
 * Get the value of orders
 * @member {Object} orders
 */
AccommodationJsonldAccommodationRead.prototype.orders = undefined;

