/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ShuttleOrderJsonldShuttleOrderWrite model module.
 * @module model/ShuttleOrderJsonldShuttleOrderWrite
 * @version 1.0.0
 */
export class ShuttleOrderJsonldShuttleOrderWrite {
  /**
   * Constructs a new <code>ShuttleOrderJsonldShuttleOrderWrite</code>.
   * @alias module:model/ShuttleOrderJsonldShuttleOrderWrite
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ShuttleOrderJsonldShuttleOrderWrite</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ShuttleOrderJsonldShuttleOrderWrite} obj Optional instance to populate.
   * @return {module:model/ShuttleOrderJsonldShuttleOrderWrite} The populated <code>ShuttleOrderJsonldShuttleOrderWrite</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ShuttleOrderJsonldShuttleOrderWrite();
      if (data.hasOwnProperty('passengerFirstName'))
        obj.passengerFirstName = ApiClient.convertToType(data['passengerFirstName'], Object);
      if (data.hasOwnProperty('passengerLastName'))
        obj.passengerLastName = ApiClient.convertToType(data['passengerLastName'], Object);
      if (data.hasOwnProperty('numberOfPassengers'))
        obj.numberOfPassengers = ApiClient.convertToType(data['numberOfPassengers'], Object);
      if (data.hasOwnProperty('pickUpDate'))
        obj.pickUpDate = ApiClient.convertToType(data['pickUpDate'], Object);
      if (data.hasOwnProperty('pickUpTime'))
        obj.pickUpTime = ApiClient.convertToType(data['pickUpTime'], Object);
      if (data.hasOwnProperty('pickUpPlace'))
        obj.pickUpPlace = ApiClient.convertToType(data['pickUpPlace'], Object);
      if (data.hasOwnProperty('dropOffPlace'))
        obj.dropOffPlace = ApiClient.convertToType(data['dropOffPlace'], Object);
      if (data.hasOwnProperty('flightNumber'))
        obj.flightNumber = ApiClient.convertToType(data['flightNumber'], Object);
      if (data.hasOwnProperty('estimatedTimeOfArrival'))
        obj.estimatedTimeOfArrival = ApiClient.convertToType(data['estimatedTimeOfArrival'], Object);
      if (data.hasOwnProperty('specialNotes'))
        obj.specialNotes = ApiClient.convertToType(data['specialNotes'], Object);
      if (data.hasOwnProperty('company'))
        obj.company = ApiClient.convertToType(data['company'], Object);
    }
    return obj;
  }
}

/**
 * Get the value of passengerFirstName
 * @member {Object} passengerFirstName
 */
ShuttleOrderJsonldShuttleOrderWrite.prototype.passengerFirstName = undefined;

/**
 * Get the value of passengerLastName
 * @member {Object} passengerLastName
 */
ShuttleOrderJsonldShuttleOrderWrite.prototype.passengerLastName = undefined;

/**
 * Get the value of numberOfPassengers
 * @member {Object} numberOfPassengers
 */
ShuttleOrderJsonldShuttleOrderWrite.prototype.numberOfPassengers = undefined;

/**
 * Get the value of pickUpDate
 * @member {Object} pickUpDate
 */
ShuttleOrderJsonldShuttleOrderWrite.prototype.pickUpDate = undefined;

/**
 * Get the value of pickUpTime
 * @member {Object} pickUpTime
 */
ShuttleOrderJsonldShuttleOrderWrite.prototype.pickUpTime = undefined;

/**
 * Get the value of pickUpPlace
 * @member {Object} pickUpPlace
 */
ShuttleOrderJsonldShuttleOrderWrite.prototype.pickUpPlace = undefined;

/**
 * Get the value of dropOffPlace
 * @member {Object} dropOffPlace
 */
ShuttleOrderJsonldShuttleOrderWrite.prototype.dropOffPlace = undefined;

/**
 * Get the value of flightNumber
 * @member {Object} flightNumber
 */
ShuttleOrderJsonldShuttleOrderWrite.prototype.flightNumber = undefined;

/**
 * Get the value of estimatedTimeOfArrival
 * @member {Object} estimatedTimeOfArrival
 */
ShuttleOrderJsonldShuttleOrderWrite.prototype.estimatedTimeOfArrival = undefined;

/**
 * Get the value of specialNotes
 * @member {Object} specialNotes
 */
ShuttleOrderJsonldShuttleOrderWrite.prototype.specialNotes = undefined;

/**
 * Get the value of company
 * @member {Object} company
 */
ShuttleOrderJsonldShuttleOrderWrite.prototype.company = undefined;

