/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The AccommodationJsonldAccommodationWrite model module.
 * @module model/AccommodationJsonldAccommodationWrite
 * @version 1.0.0
 */
export class AccommodationJsonldAccommodationWrite {
  /**
   * Constructs a new <code>AccommodationJsonldAccommodationWrite</code>.
   * @alias module:model/AccommodationJsonldAccommodationWrite
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AccommodationJsonldAccommodationWrite</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AccommodationJsonldAccommodationWrite} obj Optional instance to populate.
   * @return {module:model/AccommodationJsonldAccommodationWrite} The populated <code>AccommodationJsonldAccommodationWrite</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AccommodationJsonldAccommodationWrite();
      if (data.hasOwnProperty('numberOnMap'))
        obj.numberOnMap = ApiClient.convertToType(data['numberOnMap'], Object);
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], Object);
      if (data.hasOwnProperty('address'))
        obj.address = ApiClient.convertToType(data['address'], Object);
      if (data.hasOwnProperty('rooms'))
        obj.rooms = ApiClient.convertToType(data['rooms'], Object);
      if (data.hasOwnProperty('orders'))
        obj.orders = ApiClient.convertToType(data['orders'], Object);
    }
    return obj;
  }
}

/**
 * Get the value of NumberOnMap
 * @member {Object} numberOnMap
 */
AccommodationJsonldAccommodationWrite.prototype.numberOnMap = undefined;

/**
 * Get the value of name
 * @member {Object} name
 */
AccommodationJsonldAccommodationWrite.prototype.name = undefined;

/**
 * Get the value of address
 * @member {Object} address
 */
AccommodationJsonldAccommodationWrite.prototype.address = undefined;

/**
 * Get the value of rooms
 * @member {Object} rooms
 */
AccommodationJsonldAccommodationWrite.prototype.rooms = undefined;

/**
 * Get the value of orders
 * @member {Object} orders
 */
AccommodationJsonldAccommodationWrite.prototype.orders = undefined;

