/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The OrderJsonldOrderRead model module.
 * @module model/OrderJsonldOrderRead
 * @version 1.0.0
 */
export class OrderJsonldOrderRead {
  /**
   * Constructs a new <code>OrderJsonldOrderRead</code>.
   * @alias module:model/OrderJsonldOrderRead
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>OrderJsonldOrderRead</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OrderJsonldOrderRead} obj Optional instance to populate.
   * @return {module:model/OrderJsonldOrderRead} The populated <code>OrderJsonldOrderRead</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrderJsonldOrderRead();
      if (data.hasOwnProperty('@context'))
        obj.context = ApiClient.convertToType(data['@context'], Object);
      if (data.hasOwnProperty('@id'))
        obj.id = ApiClient.convertToType(data['@id'], Object);
      if (data.hasOwnProperty('@type'))
        obj.type = ApiClient.convertToType(data['@type'], Object);
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], Object);
      if (data.hasOwnProperty('createdAt'))
        obj.createdAt = ApiClient.convertToType(data['createdAt'], Object);
      if (data.hasOwnProperty('updatedAt'))
        obj.updatedAt = ApiClient.convertToType(data['updatedAt'], Object);
      if (data.hasOwnProperty('accommodation'))
        obj.accommodation = ApiClient.convertToType(data['accommodation'], Object);
      if (data.hasOwnProperty('orderNumber'))
        obj.orderNumber = ApiClient.convertToType(data['orderNumber'], Object);
      if (data.hasOwnProperty('code'))
        obj.code = ApiClient.convertToType(data['code'], Object);
      if (data.hasOwnProperty('firstName'))
        obj.firstName = ApiClient.convertToType(data['firstName'], Object);
      if (data.hasOwnProperty('lastName'))
        obj.lastName = ApiClient.convertToType(data['lastName'], Object);
      if (data.hasOwnProperty('dateOfArrival'))
        obj.dateOfArrival = ApiClient.convertToType(data['dateOfArrival'], Object);
      if (data.hasOwnProperty('dateOfDeparture'))
        obj.dateOfDeparture = ApiClient.convertToType(data['dateOfDeparture'], Object);
      if (data.hasOwnProperty('categories'))
        obj.categories = ApiClient.convertToType(data['categories'], Object);
      if (data.hasOwnProperty('specialRequests'))
        obj.specialRequests = ApiClient.convertToType(data['specialRequests'], Object);
      if (data.hasOwnProperty('orderConfirmationPdfUrl'))
        obj.orderConfirmationPdfUrl = ApiClient.convertToType(data['orderConfirmationPdfUrl'], Object);
      if (data.hasOwnProperty('conciergeOrderConfirmationPdfUrl'))
        obj.conciergeOrderConfirmationPdfUrl = ApiClient.convertToType(data['conciergeOrderConfirmationPdfUrl'], Object);
    }
    return obj;
  }
}

/**
 * @member {Object} context
 */
OrderJsonldOrderRead.prototype.context = undefined;

/**
 * @member {Object} id
 */
OrderJsonldOrderRead.prototype.id = undefined;

/**
 * @member {Object} type
 */
OrderJsonldOrderRead.prototype.type = undefined;

/**
 * @member {Object} id
 */
OrderJsonldOrderRead.prototype.id = undefined;

/**
 * Get the value of createdAt
 * @member {Object} createdAt
 */
OrderJsonldOrderRead.prototype.createdAt = undefined;

/**
 * Get the value of updatedAt
 * @member {Object} updatedAt
 */
OrderJsonldOrderRead.prototype.updatedAt = undefined;

/**
 * Get the value of accommodation
 * @member {Object} accommodation
 */
OrderJsonldOrderRead.prototype.accommodation = undefined;

/**
 * Get the value of orderNumber
 * @member {Object} orderNumber
 */
OrderJsonldOrderRead.prototype.orderNumber = undefined;

/**
 * Get the value of code
 * @member {Object} code
 */
OrderJsonldOrderRead.prototype.code = undefined;

/**
 * Get the value of firstName
 * @member {Object} firstName
 */
OrderJsonldOrderRead.prototype.firstName = undefined;

/**
 * Get the value of lastName
 * @member {Object} lastName
 */
OrderJsonldOrderRead.prototype.lastName = undefined;

/**
 * Get the value of dateOfArrival
 * @member {Object} dateOfArrival
 */
OrderJsonldOrderRead.prototype.dateOfArrival = undefined;

/**
 * Get the value of dateOfDeparture
 * @member {Object} dateOfDeparture
 */
OrderJsonldOrderRead.prototype.dateOfDeparture = undefined;

/**
 * @member {Object} categories
 */
OrderJsonldOrderRead.prototype.categories = undefined;

/**
 * Get the value of specialRequests
 * @member {Object} specialRequests
 */
OrderJsonldOrderRead.prototype.specialRequests = undefined;

/**
 * Get the value of orderConfirmationPdfUrl
 * @member {Object} orderConfirmationPdfUrl
 */
OrderJsonldOrderRead.prototype.orderConfirmationPdfUrl = undefined;

/**
 * Get the value of conciergeOrderConfirmationPdfUrl
 * @member {Object} conciergeOrderConfirmationPdfUrl
 */
OrderJsonldOrderRead.prototype.conciergeOrderConfirmationPdfUrl = undefined;

