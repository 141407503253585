import React, { useState } from "react";
import { lightenColor } from "utils/utils";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";
import MDBox from "components/MDBox";

function Category({ category, onItemsChange }) {
  const [items, setItems] = useState(
    category.items.length ? category.items : [{ quantity: "", name: "", kind: "" }]
  );
  const categoryColor = category.colorHex;
  const lighterColor = lightenColor(categoryColor, 20);
  const categoryGradient = `linear-gradient(70deg, ${categoryColor} 20%, ${lighterColor} 80%)`;

  console.log(category);

  const handleItemChange = (index, key, value) => {
    setItems((prevItems) =>
      prevItems.map((item, i) => (i === index ? { ...item, [key]: value } : item))
    );
    onItemsChange(items);
  };

  const handleAddItem = () => {
    const newItems = [...items, { quantity: "", name: "", kind: "" }];
    setItems(newItems);
    onItemsChange(newItems);
  };

  const handleDeleteItem = (index) => {
    if (items.length > 1) {
      const newItems = items.filter((_, i) => i !== index);
      setItems(newItems);
      onItemsChange(newItems);
    }
  };

  return (
    <Box py={2}>
      <Card variant="outlined">
        <CardContent>
          <MDBox
            my={2}
            p={2}
            sx={{
              background: categoryGradient,
              borderRadius: "0.75rem",
            }}
          >
            <h2 style={{ margin: 0, lineHeight: 1, color: "white" }}>{category.name}</h2>
          </MDBox>
          {items.map((item, index) => (
            <Box key={index}>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12} md={1}>
                  <TextField
                    value={item.quantity}
                    label="Quantity"
                    type="number"
                    fullWidth
                    onChange={(e) => handleItemChange(index, "quantity", parseInt(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    value={item.name}
                    label="Name"
                    fullWidth
                    onChange={(e) => handleItemChange(index, "name", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    value={item.kind}
                    label="Kind"
                    fullWidth
                    onChange={(e) => handleItemChange(index, "kind", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  {items.length > 1 && (
                    <Button onClick={() => handleDeleteItem(index)}>Delete</Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
          <Button onClick={handleAddItem}>Add Item</Button>
          <TextField label={`Comment for ${category.name}`} fullWidth />
        </CardContent>
      </Card>
    </Box>
  );
}

Category.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        name: PropTypes.string,
        kind: PropTypes.string,
      })
    ),
    colorHex: PropTypes.string,
  }),
  onItemsChange: PropTypes.func,
};

export default Category;
