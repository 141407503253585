/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ShuttleOrderJsonldShuttleOrderRead model module.
 * @module model/ShuttleOrderJsonldShuttleOrderRead
 * @version 1.0.0
 */
export class ShuttleOrderJsonldShuttleOrderRead {
  /**
   * Constructs a new <code>ShuttleOrderJsonldShuttleOrderRead</code>.
   * @alias module:model/ShuttleOrderJsonldShuttleOrderRead
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ShuttleOrderJsonldShuttleOrderRead</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ShuttleOrderJsonldShuttleOrderRead} obj Optional instance to populate.
   * @return {module:model/ShuttleOrderJsonldShuttleOrderRead} The populated <code>ShuttleOrderJsonldShuttleOrderRead</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ShuttleOrderJsonldShuttleOrderRead();
      if (data.hasOwnProperty('@context'))
        obj.context = ApiClient.convertToType(data['@context'], Object);
      if (data.hasOwnProperty('@id'))
        obj.id = ApiClient.convertToType(data['@id'], Object);
      if (data.hasOwnProperty('@type'))
        obj.type = ApiClient.convertToType(data['@type'], Object);
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], Object);
      if (data.hasOwnProperty('orderNumber'))
        obj.orderNumber = ApiClient.convertToType(data['orderNumber'], Object);
      if (data.hasOwnProperty('code'))
        obj.code = ApiClient.convertToType(data['code'], Object);
      if (data.hasOwnProperty('createdAt'))
        obj.createdAt = ApiClient.convertToType(data['createdAt'], Object);
      if (data.hasOwnProperty('updatedAt'))
        obj.updatedAt = ApiClient.convertToType(data['updatedAt'], Object);
      if (data.hasOwnProperty('passengerFirstName'))
        obj.passengerFirstName = ApiClient.convertToType(data['passengerFirstName'], Object);
      if (data.hasOwnProperty('passengerLastName'))
        obj.passengerLastName = ApiClient.convertToType(data['passengerLastName'], Object);
      if (data.hasOwnProperty('numberOfPassengers'))
        obj.numberOfPassengers = ApiClient.convertToType(data['numberOfPassengers'], Object);
      if (data.hasOwnProperty('pickUpDate'))
        obj.pickUpDate = ApiClient.convertToType(data['pickUpDate'], Object);
      if (data.hasOwnProperty('pickUpTime'))
        obj.pickUpTime = ApiClient.convertToType(data['pickUpTime'], Object);
      if (data.hasOwnProperty('pickUpPlace'))
        obj.pickUpPlace = ApiClient.convertToType(data['pickUpPlace'], Object);
      if (data.hasOwnProperty('dropOffPlace'))
        obj.dropOffPlace = ApiClient.convertToType(data['dropOffPlace'], Object);
      if (data.hasOwnProperty('flightNumber'))
        obj.flightNumber = ApiClient.convertToType(data['flightNumber'], Object);
      if (data.hasOwnProperty('estimatedTimeOfArrival'))
        obj.estimatedTimeOfArrival = ApiClient.convertToType(data['estimatedTimeOfArrival'], Object);
      if (data.hasOwnProperty('specialNotes'))
        obj.specialNotes = ApiClient.convertToType(data['specialNotes'], Object);
      if (data.hasOwnProperty('company'))
        obj.company = ApiClient.convertToType(data['company'], Object);
      if (data.hasOwnProperty('orderConfirmationPdfUrl'))
        obj.orderConfirmationPdfUrl = ApiClient.convertToType(data['orderConfirmationPdfUrl'], Object);
    }
    return obj;
  }
}

/**
 * @member {Object} context
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.context = undefined;

/**
 * @member {Object} id
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.id = undefined;

/**
 * @member {Object} type
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.type = undefined;

/**
 * Get the value of id
 * @member {Object} id
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.id = undefined;

/**
 * Get the value of orderNumber
 * @member {Object} orderNumber
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.orderNumber = undefined;

/**
 * Get the value of code
 * @member {Object} code
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.code = undefined;

/**
 * Get the value of createdAt
 * @member {Object} createdAt
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.createdAt = undefined;

/**
 * Get the value of updatedAt
 * @member {Object} updatedAt
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.updatedAt = undefined;

/**
 * Get the value of passengerFirstName
 * @member {Object} passengerFirstName
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.passengerFirstName = undefined;

/**
 * Get the value of passengerLastName
 * @member {Object} passengerLastName
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.passengerLastName = undefined;

/**
 * Get the value of numberOfPassengers
 * @member {Object} numberOfPassengers
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.numberOfPassengers = undefined;

/**
 * Get the value of pickUpDate
 * @member {Object} pickUpDate
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.pickUpDate = undefined;

/**
 * Get the value of pickUpTime
 * @member {Object} pickUpTime
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.pickUpTime = undefined;

/**
 * Get the value of pickUpPlace
 * @member {Object} pickUpPlace
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.pickUpPlace = undefined;

/**
 * Get the value of dropOffPlace
 * @member {Object} dropOffPlace
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.dropOffPlace = undefined;

/**
 * Get the value of flightNumber
 * @member {Object} flightNumber
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.flightNumber = undefined;

/**
 * Get the value of estimatedTimeOfArrival
 * @member {Object} estimatedTimeOfArrival
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.estimatedTimeOfArrival = undefined;

/**
 * Get the value of specialNotes
 * @member {Object} specialNotes
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.specialNotes = undefined;

/**
 * Get the value of company
 * @member {Object} company
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.company = undefined;

/**
 * Get the value of orderConfirmationPdfUrl
 * @member {Object} orderConfirmationPdfUrl
 */
ShuttleOrderJsonldShuttleOrderRead.prototype.orderConfirmationPdfUrl = undefined;

