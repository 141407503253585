import { CompanyApi } from "api/src";
import ConciergeLayout from "components/concierge/components/ConciergeLayout";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import bgImage from "assets/images/shuttle/davos.jpg";
import mapImage from "assets/images/shuttle/square.png";
import headerImage from "assets/images/shuttle/header.jpg";

// Material-UI imports
import { Card, CardContent, CardMedia, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import GetAppIcon from "@mui/icons-material/GetApp";

// API imports
import { AccommodationApi } from "api/src/api/AccommodationApi"; // adjust the import according to your project structure
import { TemplateCategoryApi } from "api/src";
import { OrderApi } from "api/src";

// Component imports
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ShuttleMapComponent from "./components/ShuttleMapComponent";
import LocationAutocomplete from "./components/LocationAutocomplete";
import { LoadScript } from "@react-google-maps/api";

import locations from "./components/addresses.json";
import { ShuttleOrderApi } from "api/src";

const libraries = ["places"];

const Shuttle = () => {
  const { companyCode } = useParams();
  const [company, setCompany] = useState([]);
  const [shuttleOrder, setShuttleOrder] = useState({
    passengerFirstName: "",
    passengerLastName: "",
    numberOfPassengers: 1,
    pickUpDate: "",
    pickUpTime: "",
    pickUpPlace: null,
    dropOffPlace: null,
    flightNumber: "",
    estimatedTimeOfArrival: null,
    specialNotes: "",
    company: {},
  });
  const [shuttleOrderResponse, setShuttleOrderResponse] = useState(null);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropoffAddress, setDropoffAddress] = useState(null);
  const [pickupError, setPickupError] = useState(false);
  const [dropoffError, setDropoffError] = useState(false);

  // Create a ref for the top element
  const topElementRef = useRef(null);

  const companyApi = new CompanyApi();
  const shuttleOrderApi = new ShuttleOrderApi();

  const handleDownload = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;

    // Extract filename from the URL
    const urlObj = new URL(url);
    const filename = urlObj.pathname.split("/").pop();

    link.download = filename;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleSubmit = () => {
    let orderToSubmit = {
      ...shuttleOrder,
      company: company.id,
    };

    // Check if pickup address is empty or null
    if (!shuttleOrder.pickUpPlace || shuttleOrder.pickUpPlace === "") {
      setPickupError(true);
    } else {
      setPickupError(false);
    }

    // Check if dropoff address is empty or null
    if (!shuttleOrder.dropOffPlace || shuttleOrder.dropOffPlace === "") {
      setDropoffError(true);
    } else {
      setDropoffError(false);
    }

    if (pickupError || dropoffError) {
      return;
    }

    // Replace null values in addresses with an empty string
    ["pickUpPlace", "dropOffPlace"].forEach((key) => {
      if (orderToSubmit[key]) {
        Object.keys(orderToSubmit[key]).forEach((subKey) => {
          if (orderToSubmit[key][subKey] === null) {
            orderToSubmit[key][subKey] = "";
          }
        });
      }
    });

    // Parse numberOfPassengers to an integer
    if (orderToSubmit.numberOfPassengers) {
      orderToSubmit.numberOfPassengers = parseInt(orderToSubmit.numberOfPassengers, 10);
    }

    console.log(orderToSubmit);
    shuttleOrderApi.apiShuttleOrdersPost(orderToSubmit, (error, data, response) => {
      if (error) {
        console.error("An error occurred while submitting the shuttle order:", error);
      } else {
        setShuttleOrderResponse(data);
        topElementRef.current.scrollIntoView({ behavior: "smooth" });
      }
    });
  };

  const onPickupChange = (selectedPickup) => {
    setPickupAddress(selectedPickup);
    setShuttleOrder({ ...shuttleOrder, pickUpPlace: selectedPickup });
  };

  const onDropoffChange = (selectedDropOff) => {
    setDropoffAddress(selectedDropOff);
    setShuttleOrder({ ...shuttleOrder, dropOffPlace: selectedDropOff });
  };

  // Fetch the accommodations
  useEffect(() => {
    if (companyCode) {
      companyApi.apiCompaniesCodeGet(companyCode, (error, data, response) => {
        if (error) {
          console.error("An error occurred while fetching the company:", error);
        } else {
          setCompany(data);
        }
      });
    }
  }, [companyCode]);

  if (!companyCode) {
    return <div>Please use the link provided to you.</div>;
  }

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <ConciergeLayout image={bgImage}>
        <form
          onSubmit={(e) => {
            e.preventDefault(); // Prevent the default form submission
            handleSubmit(); // Call your submit function
          }}
        >
          <MDBox py={2}>
            <Card sx={{ borderRadius: 1, boxShadow: 5 }} variant="outlined">
              <CardContent p={4}>
                <div ref={topElementRef}>
                  <CardMedia
                    component="img"
                    sx={{ width: "100%", marginLeft: 0, marginRight: 0, marginBottom: 4 }}
                    image={headerImage}
                    title="header"
                  />
                </div>
                {shuttleOrderResponse ? (
                  <Grid container justifyContent="center" alignItems="center" direction="column">
                    <MDTypography variant="h4" gutterBottom>
                      Order Confirmation
                    </MDTypography>
                    <MDTypography variant="body1" gutterBottom>
                      Thank you for your Order! You can download the order confirmation below.
                    </MDTypography>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      style={{ maxWidth: "500px", marginBottom: "20px" }}
                    >
                      <Grid container justifyContent="space-between">
                        <Grid item xs={6}>
                          <MDTypography variant="body1" fontWeight="bold">
                            Full Name:
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="body1">{`${shuttleOrderResponse.passengerFirstName} ${shuttleOrderResponse.passengerLastName}`}</MDTypography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={6}>
                          <MDTypography variant="body1" fontWeight="bold">
                            Order Number:
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="body1">
                            {shuttleOrderResponse.orderNumber}
                          </MDTypography>
                        </Grid>
                      </Grid>
                      {/* ... */}
                      <Grid container justifyContent="space-between">
                        <Grid item xs={6}>
                          <MDTypography variant="body1" fontWeight="bold">
                            Pickup Location:
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="body1">
                            {shuttleOrderResponse.pickUpPlace.name ? (
                              <>
                                {shuttleOrderResponse.pickUpPlace.name} <br />
                              </>
                            ) : null}
                            {[
                              shuttleOrderResponse.pickUpPlace.street,
                              shuttleOrderResponse.pickUpPlace.zipCode,
                              shuttleOrderResponse.pickUpPlace.city,
                              shuttleOrderResponse.pickUpPlace.country,
                            ]
                              .filter(Boolean)
                              .join(", ")}
                          </MDTypography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={6}>
                          <MDTypography variant="body1" fontWeight="bold">
                            Dropoff Location:
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="body1">
                            {shuttleOrderResponse.dropOffPlace.name ? (
                              <>
                                {shuttleOrderResponse.dropOffPlace.name} <br />
                              </>
                            ) : null}
                            {[
                              shuttleOrderResponse.dropOffPlace.street,
                              shuttleOrderResponse.dropOffPlace.zipCode,
                              shuttleOrderResponse.dropOffPlace.city,
                              shuttleOrderResponse.dropOffPlace.country,
                            ]
                              .filter(Boolean)
                              .join(", ")}
                          </MDTypography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={6}>
                          <MDTypography variant="body1" fontWeight="bold">
                            Pickup Date/Time:
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="body1">
                            {`${new Date(
                              shuttleOrderResponse.pickUpDate
                            ).toLocaleDateString()} - ${new Date(
                              shuttleOrderResponse.pickUpTime
                            ).toLocaleDateString()}`}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <MDButton
                      variant="contained"
                      color="success"
                      startIcon={<GetAppIcon />}
                      onClick={() =>
                        handleDownload(
                          `${shuttleOrderResponse.orderConfirmationPdfUrl}?code=${shuttleOrderResponse.code}`
                        )
                      }
                    >
                      Download Order Confirmation
                    </MDButton>
                  </Grid>
                ) : (
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Box borderRadius={4} overflow="hidden" sx={{ height: "100%" }}>
                        <ShuttleMapComponent
                          onPickupChange={onPickupChange}
                          onDropoffChange={onDropoffChange}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="First Name"
                                onChange={(e) =>
                                  setShuttleOrder({
                                    ...shuttleOrder,
                                    passengerFirstName: e.target.value,
                                  })
                                }
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="Last Name"
                                onChange={(e) =>
                                  setShuttleOrder({
                                    ...shuttleOrder,
                                    passengerLastName: e.target.value,
                                  })
                                }
                                fullWidth
                                required
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={2}>
                              <TextField
                                label="PAX"
                                type="number"
                                defaultValue={1}
                                onChange={(e) =>
                                  setShuttleOrder({
                                    ...shuttleOrder,
                                    numberOfPassengers: e.target.value,
                                  })
                                }
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                label="Date"
                                type="date"
                                fullWidth
                                onChange={(e) =>
                                  setShuttleOrder({ ...shuttleOrder, pickUpDate: e.target.value })
                                }
                                InputLabelProps={{ shrink: true }}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="Time"
                                type="time"
                                fullWidth
                                onChange={(e) =>
                                  setShuttleOrder({ ...shuttleOrder, pickUpTime: e.target.value })
                                }
                                InputLabelProps={{ shrink: true }}
                                required
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="Flight Number (if applicable)"
                                fullWidth
                                onChange={(e) =>
                                  setShuttleOrder({ ...shuttleOrder, flightNumber: e.target.value })
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="Estimated Time of Arrival (flight)"
                                type="time"
                                fullWidth
                                onChange={(e) =>
                                  setShuttleOrder({
                                    ...shuttleOrder,
                                    estimatedTimeOfArrival: e.target.value,
                                  })
                                }
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <MDTypography variant="body2">
                            Select Pickup and Drop-off Location from map or search below:
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <LocationAutocomplete
                                error={pickupError}
                                label="Pickup Location *"
                                onAddressChange={onPickupChange}
                                address={pickupAddress}
                                additionalOptions={locations}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <LocationAutocomplete
                                error={dropoffError}
                                label="Drop-off Location *"
                                onAddressChange={onDropoffChange}
                                address={dropoffAddress}
                                additionalOptions={locations}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label="Special Notes"
                                fullWidth
                                multiline
                                rows={4}
                                onChange={(e) =>
                                  setShuttleOrder({ ...shuttleOrder, specialNotes: e.target.value })
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                          <MDTypography variant="body2" gutterBottom align="justify">
                            <b>
                              For assistance, contact our Shuttle Operations Coordinator Office:
                            </b>
                            <br />
                            <a href="tel:+41765169011">+41 76 516 90 11</a>
                            <br /> <em>(Call directly or Message via WhatsApp)</em>
                          </MDTypography>
                          <Divider />
                          <MDButton
                            variant="gradient"
                            color="info"
                            style={{ marginTop: "16px", background: "#002985" }}
                            type="submit"
                          >
                            Place Order
                          </MDButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </MDBox>
        </form>
      </ConciergeLayout>
    </LoadScript>
  );
};

export default Shuttle;
