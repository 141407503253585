/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {InlineResponse2006} from '../model/InlineResponse2006';
import {OrderJsonldOrderRead} from '../model/OrderJsonldOrderRead';
import {OrderJsonldOrderWrite} from '../model/OrderJsonldOrderWrite';

/**
* Order service.
* @module api/OrderApi
* @version 1.0.0
*/
export class OrderApi {

    /**
    * Constructs a new OrderApi. 
    * @alias module:api/OrderApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiOrdersGetCollection operation.
     * @callback moduleapi/OrderApi~apiOrdersGetCollectionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2006{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves the collection of Order resources.
     * Retrieves the collection of Order resources.
     * @param {Object} opts Optional parameters
     * @param {Object} opts.page The collection page number (default to <.>)
     * @param {module:api/OrderApi~apiOrdersGetCollectionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiOrdersGetCollection(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'page': opts['page']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/ld+json'];
      let returnType = InlineResponse2006;

      return this.apiClient.callApi(
        '/api/orders', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiOrdersIdDelete operation.
     * @callback moduleapi/OrderApi~apiOrdersIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Removes the Order resource.
     * Removes the Order resource.
     * @param {Object} id Order identifier
     * @param {module:api/OrderApi~apiOrdersIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiOrdersIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiOrdersIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/orders/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiOrdersIdGet operation.
     * @callback moduleapi/OrderApi~apiOrdersIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderJsonldOrderRead{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves a Order resource.
     * Retrieves a Order resource.
     * @param {Object} id Order identifier
     * @param {module:api/OrderApi~apiOrdersIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiOrdersIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiOrdersIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/ld+json'];
      let returnType = OrderJsonldOrderRead;

      return this.apiClient.callApi(
        '/api/orders/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiOrdersIdPut operation.
     * @callback moduleapi/OrderApi~apiOrdersIdPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderJsonldOrderRead{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Replaces the Order resource.
     * Replaces the Order resource.
     * @param {module:model/OrderJsonldOrderWrite} body The updated Order resource
     * @param {Object} id Order identifier
     * @param {module:api/OrderApi~apiOrdersIdPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiOrdersIdPut(body, id, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling apiOrdersIdPut");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiOrdersIdPut");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/ld+json'];
      let accepts = ['application/ld+json'];
      let returnType = OrderJsonldOrderRead;

      return this.apiClient.callApi(
        '/api/orders/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiOrdersPost operation.
     * @callback moduleapi/OrderApi~apiOrdersPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderJsonldOrderRead{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates a Order resource.
     * Creates a Order resource.
     * @param {module:model/OrderJsonldOrderWrite} body The new Order resource
     * @param {module:api/OrderApi~apiOrdersPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiOrdersPost(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling apiOrdersPost");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/ld+json'];
      let accepts = ['application/ld+json'];
      let returnType = OrderJsonldOrderRead;

      return this.apiClient.callApi(
        '/api/orders', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}