/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AccommodationJsonldRoomRead} from './AccommodationJsonldRoomRead';

/**
 * The RoomJsonldRoomRead model module.
 * @module model/RoomJsonldRoomRead
 * @version 1.0.0
 */
export class RoomJsonldRoomRead {
  /**
   * Constructs a new <code>RoomJsonldRoomRead</code>.
   * @alias module:model/RoomJsonldRoomRead
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RoomJsonldRoomRead</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RoomJsonldRoomRead} obj Optional instance to populate.
   * @return {module:model/RoomJsonldRoomRead} The populated <code>RoomJsonldRoomRead</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RoomJsonldRoomRead();
      if (data.hasOwnProperty('@context'))
        obj.context = ApiClient.convertToType(data['@context'], Object);
      if (data.hasOwnProperty('@id'))
        obj.id = ApiClient.convertToType(data['@id'], Object);
      if (data.hasOwnProperty('@type'))
        obj.type = ApiClient.convertToType(data['@type'], Object);
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], Object);
      if (data.hasOwnProperty('roomNumber'))
        obj.roomNumber = ApiClient.convertToType(data['roomNumber'], Object);
      if (data.hasOwnProperty('roomType'))
        obj.roomType = ApiClient.convertToType(data['roomType'], Object);
      if (data.hasOwnProperty('accommodation'))
        obj.accommodation = AccommodationJsonldRoomRead.constructFromObject(data['accommodation']);
    }
    return obj;
  }
}

/**
 * @member {Object} context
 */
RoomJsonldRoomRead.prototype.context = undefined;

/**
 * @member {Object} id
 */
RoomJsonldRoomRead.prototype.id = undefined;

/**
 * @member {Object} type
 */
RoomJsonldRoomRead.prototype.type = undefined;

/**
 * Get room ID.
 * @member {Object} id
 */
RoomJsonldRoomRead.prototype.id = undefined;

/**
 * Get room number.
 * @member {Object} roomNumber
 */
RoomJsonldRoomRead.prototype.roomNumber = undefined;

/**
 * Get room type.
 * @member {Object} roomType
 */
RoomJsonldRoomRead.prototype.roomType = undefined;

/**
 * Get accommodation.
 * @member {module:model/AccommodationJsonldRoomRead} accommodation
 */
RoomJsonldRoomRead.prototype.accommodation = undefined;

