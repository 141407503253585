/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {InlineResponse2001Hydraview} from './InlineResponse2001Hydraview';
import {InlineResponse200Hydrasearch} from './InlineResponse200Hydrasearch';

/**
 * The InlineResponse2001 model module.
 * @module model/InlineResponse2001
 * @version 1.0.0
 */
export class InlineResponse2001 {
  /**
   * Constructs a new <code>InlineResponse2001</code>.
   * @alias module:model/InlineResponse2001
   * @class
   * @param hydramember {Object} 
   */
  constructor(hydramember) {
    this.hydramember = hydramember;
  }

  /**
   * Constructs a <code>InlineResponse2001</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse2001} obj Optional instance to populate.
   * @return {module:model/InlineResponse2001} The populated <code>InlineResponse2001</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse2001();
      if (data.hasOwnProperty('hydra:member'))
        obj.hydramember = ApiClient.convertToType(data['hydra:member'], Object);
      if (data.hasOwnProperty('hydra:totalItems'))
        obj.hydratotalItems = ApiClient.convertToType(data['hydra:totalItems'], Object);
      if (data.hasOwnProperty('hydra:view'))
        obj.hydraview = InlineResponse2001Hydraview.constructFromObject(data['hydra:view']);
      if (data.hasOwnProperty('hydra:search'))
        obj.hydrasearch = InlineResponse200Hydrasearch.constructFromObject(data['hydra:search']);
    }
    return obj;
  }
}

/**
 * @member {Object} hydramember
 */
InlineResponse2001.prototype.hydramember = undefined;

/**
 * @member {Object} hydratotalItems
 */
InlineResponse2001.prototype.hydratotalItems = undefined;

/**
 * @member {module:model/InlineResponse2001Hydraview} hydraview
 */
InlineResponse2001.prototype.hydraview = undefined;

/**
 * @member {module:model/InlineResponse200Hydrasearch} hydrasearch
 */
InlineResponse2001.prototype.hydrasearch = undefined;

