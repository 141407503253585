/*
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.51
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The RoomJsonldAccommodationWrite model module.
 * @module model/RoomJsonldAccommodationWrite
 * @version 1.0.0
 */
export class RoomJsonldAccommodationWrite {
  /**
   * Constructs a new <code>RoomJsonldAccommodationWrite</code>.
   * @alias module:model/RoomJsonldAccommodationWrite
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RoomJsonldAccommodationWrite</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RoomJsonldAccommodationWrite} obj Optional instance to populate.
   * @return {module:model/RoomJsonldAccommodationWrite} The populated <code>RoomJsonldAccommodationWrite</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RoomJsonldAccommodationWrite();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], Object);
      if (data.hasOwnProperty('roomNumber'))
        obj.roomNumber = ApiClient.convertToType(data['roomNumber'], Object);
      if (data.hasOwnProperty('roomType'))
        obj.roomType = ApiClient.convertToType(data['roomType'], Object);
    }
    return obj;
  }
}

/**
 * Get room ID.
 * @member {Object} id
 */
RoomJsonldAccommodationWrite.prototype.id = undefined;

/**
 * Get room number.
 * @member {Object} roomNumber
 */
RoomJsonldAccommodationWrite.prototype.roomNumber = undefined;

/**
 * Get room type.
 * @member {Object} roomType
 */
RoomJsonldAccommodationWrite.prototype.roomType = undefined;

